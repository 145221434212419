import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';
import { useI18n } from 'services/i18n';
import { Background, Container, Row, Col } from 'styles/grid';
import { BiggerText, SecondaryTitle, Text } from 'components/texts';
import NonStretchedImage from 'components/non-stretched-image';
import Links from 'components/links';
import {
  StyledCol,
  ArticleCol,
  BenefitsContainer,
  BenefitsCol,
  LeftTitle,
  LeftText,
  DescriptionContainer,
  CaseStudyLink,
  DescriptionItem,
} from 'components/case-study';

const CaseStudyMaisonKitsune = () => {
  const [t, language] = useI18n();
  const data = useStaticQuery(graphql`
    query {
      intro: file(relativePath: { eq: "case-study-maisonkitsune/intro.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_en: file(relativePath: { eq: "case-study-maisonkitsune/en/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      case_study_fr: file(relativePath: { eq: "case-study-maisonkitsune/fr/caseStudy.png" }) {
        childImageSharp {
          fluid(maxWidth: 1013, quality: 100) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <section>
        <SEO
          title={t('seo.title.case-study-maison-kitsune')}
          lang={language}
          description={t('seo.description.case-study-maison-kitsune')}
        />
        <Container>
          <Row>
            <Col sm={12} md={8}>
              <BiggerText color="fitle" weight="bold">
                {t('case-study-maisonkitsune.introductory-bigger-text')}
              </BiggerText>
              <SecondaryTitle as="h1" desktopAlign="left">
                {t('case-study-maisonkitsune.title')}
              </SecondaryTitle>
            </Col>
            <StyledCol sm={12} md={4} align="center">
              <NonStretchedImage
                fluid={data.intro.childImageSharp.fluid}
                alt={t(`seo.alt.caseStudyMaisonKitsune.intro`)}
              />
            </StyledCol>
          </Row>
        </Container>
      </section>
      <Background>
        <section>
          <BenefitsContainer>
            <Row justify="space-around" align="stretch">
              <BenefitsCol sm={10} md={3}>
                <Text desktopAlign="center" style={{ marginBottom: 10 }}>
                  {t('case-study-maisonkitsune.reco-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-maisonkitsune.reco-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text
                  desktopAlign="center"
                  style={{ margin: '20px auto 10px auto', maxWidth: 300 }}
                >
                  {t('case-study-maisonkitsune.usage-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-maisonkitsune.usage-value')}
                </BiggerText>
              </BenefitsCol>
              <BenefitsCol sm={10} md={3}>
                <Text
                  desktopAlign="center"
                  style={{ margin: '20px auto 10px auto', maxWidth: 300 }}
                >
                  {t('case-study-maisonkitsune.conversion-text')}
                </Text>
                <BiggerText
                  desktopAlign="center"
                  color="fitle"
                  weight="bold"
                  style={{ marginTop: 0 }}
                >
                  {t('case-study-maisonkitsune.conversion-value')}
                </BiggerText>
              </BenefitsCol>
            </Row>
          </BenefitsContainer>
        </section>
      </Background>
      <section>
        <Container>
          <Row>
            <DescriptionContainer>
              <DescriptionItem label={t('case-study-maisonkitsune.used-product-key')}>
                <>
                  <CaseStudyLink to="/plugin">
                    {t('case-study-maisonkitsune.used-product-plugin')}
                  </CaseStudyLink>
                  <CaseStudyLink to="/dashboard">
                    {t('case-study-maisonkitsune.used-product-dashboard')}
                  </CaseStudyLink>
                </>
              </DescriptionItem>
              <DescriptionItem
                label={t('case-study-maisonkitsune.headquarters-key')}
                value={t('case-study-maisonkitsune.headquarters-value')}
              />
              <DescriptionItem
                label={t('case-study-maisonkitsune.business-type-key')}
                value={t('case-study-maisonkitsune.business-type-value')}
              />
            </DescriptionContainer>
            <Col md={1}></Col>
            <ArticleCol sm={12} md={8}>
              <SecondaryTitle desktopAlign="left" align="left">
                {t('case-study-maisonkitsune.article-title')}
              </SecondaryTitle>
              {/* block one */}
              <LeftText>{t('case-study-maisonkitsune.article-block-one.paragraph-one')}</LeftText>
              {/* block two */}
              <LeftTitle>{t('case-study-maisonkitsune.article-block-two.title')}</LeftTitle>
              <LeftText>{t('case-study-maisonkitsune.article-block-two.paragraph-one')}</LeftText>
              <LeftText>{t('case-study-maisonkitsune.article-block-two.paragraph-two')}</LeftText>
              <LeftText>{t('case-study-maisonkitsune.article-block-two.paragraph-three')}</LeftText>
              {/* block three */}
              <LeftTitle>{t('case-study-maisonkitsune.article-block-three.title')}</LeftTitle>
              <LeftText>{t('case-study-maisonkitsune.article-block-three.paragraph-one')}</LeftText>
              <LeftText>{t('case-study-maisonkitsune.article-block-three.paragraph-two')}</LeftText>
              <LeftText>
                {t('case-study-maisonkitsune.article-block-three.paragraph-three')}
              </LeftText>
              <div style={{ marginTop: 80 }}>
                <NonStretchedImage
                  fluid={data['case_study_' + language].childImageSharp.fluid}
                  alt={t(`seo.alt.caseStudyMaisonKitsune.caseStudy`)}
                />
              </div>
            </ArticleCol>
          </Row>
        </Container>
      </section>
      <Links withTitle={true} links={['plugin', 'dashboard']} />
    </Layout>
  );
};

export default CaseStudyMaisonKitsune;
